<template>
  <div>
    <h1>This is a HELP page</h1>
    <v-btn> Click Me Once </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Help'
}
</script>